import * as React from "react";
const SVGComponent = (props) => (
  <svg
    style={{ width: "9rem", height: "9rem" }}
    viewBox="0 0 260 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.6795 108C48.3734 108 59.4744 96.8998 59.4744 83.2071C59.4744 69.5143 48.3734 58.4142 34.6795 58.4142C20.9856 58.4142 9.88458 69.5143 9.88458 83.2071C9.88458 96.8998 20.9856 108 34.6795 108Z"
      fill="#997951"
    />
    <path
      d="M218.657 108C232.351 108 243.452 96.8998 243.452 83.2071C243.452 69.5143 232.351 58.4142 218.657 58.4142C204.963 58.4142 193.862 69.5143 193.862 83.2071C193.862 96.8998 204.963 108 218.657 108Z"
      fill="#997951"
    />
    <path
      d="M259.285 76.3847C257.458 77.4008 248.257 77.7384 246.193 73.1721C244.129 68.5712 238.176 52.9435 218.657 52.5038C199.137 52.0305 189.936 69.9914 189.259 81.1882C189.023 85.1458 185.944 86.7021 180.294 86.7021C178.4 86.7021 153.908 86.9382 128.807 87.1753C127.758 87.1753 126.71 87.2094 125.661 87.2094C123.564 87.2094 121.466 87.2434 119.369 87.2769C118.354 87.2769 117.305 87.2769 116.29 87.3109C113.178 87.3109 110.167 87.3449 107.19 87.378C106.209 87.378 105.228 87.4125 104.281 87.4125C96.0607 87.513 88.7198 87.6146 83.2055 87.6826C77.6576 87.7502 73.9704 87.8182 73.0909 87.8517C68.0503 88.0888 66.4268 84.6385 66.1897 82.1017C65.9866 79.5984 60.9125 52.3347 37.0632 52.3007C16.935 52.2667 7.36125 68.3005 1.40767 76.7569C1.37364 76.7569 1.37364 76.7909 1.37364 76.7909C0.595676 77.9751 -0.385416 78.0091 0.155912 75.5058C0.155912 75.4382 0.189935 75.3702 0.189935 75.3027C1.91548 67.7597 5.33204 53.2482 6.51574 51.5908C16.5633 37.2829 74.918 29.2992 80.4323 29.2992L99.7144 3.05054C105.702 0.0744961 137.603 -1.00858 173.191 1.08902C176.202 1.2576 179.246 1.73135 182.291 2.44172C184.083 2.88094 185.91 3.38871 187.703 3.99753C188.651 4.30219 189.598 4.63986 190.545 4.97854C191.458 5.35023 192.406 5.72242 193.353 6.09513C195.214 6.87303 197.04 7.71898 198.867 8.66597C199.781 9.13921 200.694 9.61296 201.607 10.1202C203.4 11.1012 205.193 12.1503 206.953 13.2318C208.712 14.3144 210.437 15.465 212.129 16.6481C213.82 17.7987 215.478 19.0158 217.101 20.2675C217.71 20.7067 218.285 21.181 218.86 21.6202C218.894 21.6542 218.894 21.6542 218.894 21.6542C221.533 23.7178 224.002 25.7809 226.37 27.9125C227.115 28.5548 227.825 29.1982 228.536 29.841C230.87 31.9376 233.035 34.0692 235.065 36.0982C236.147 37.1808 237.196 38.2634 238.143 39.3114C242.439 43.8108 245.856 47.9034 248.224 50.982C248.224 50.982 248.224 51.015 248.258 51.015C249.341 52.4363 250.186 53.6544 250.795 54.5679C257.56 65.0198 261.755 74.9985 259.285 76.3847Z"
      fill="#D06170"
    />
    <path
      d="M34.6795 97.7968C42.7379 97.7968 49.2705 91.2648 49.2705 83.2071C49.2705 75.1494 42.7379 68.6173 34.6795 68.6173C26.6212 68.6173 20.0886 75.1494 20.0886 83.2071C20.0886 91.2648 26.6212 97.7968 34.6795 97.7968Z"
      fill="#D7C8A8"
    />
    <path
      d="M218.657 97.7968C226.715 97.7968 233.248 91.2648 233.248 83.2071C233.248 75.1494 226.715 68.6173 218.657 68.6173C210.599 68.6173 204.066 75.1494 204.066 83.2071C204.066 91.2648 210.599 97.7968 218.657 97.7968Z"
      fill="#D7C8A8"
    />
    <path
      d="M117.717 7.90334C108.936 7.90334 102.048 9.74756 96.7326 20.4722C90.9878 32.063 94.1408 32.292 100.693 32.352C107.529 32.4149 133.016 32.5957 139.78 32.352C145.297 32.1534 148.217 31.3187 148.389 24.2596C148.561 17.2006 148.454 15.1756 148.389 12.588C148.281 8.2339 145.439 7.90283 138.23 7.90283C130.487 7.90334 117.717 7.90334 117.717 7.90334Z"
      fill="#C4E6F4"
    />
    <path
      d="M154.228 14.2895C154.169 17.9048 154.072 24.4322 154.416 26.3262C154.76 28.2202 154.588 32.1803 160.615 32.3524C166.641 32.5246 195.913 32.5246 198.324 32.3524C200.734 32.1803 204.559 28.9214 201.768 23.9158C197.068 15.4874 189.261 7.90381 158.204 7.90381C155.161 7.9033 154.287 10.6793 154.228 14.2895Z"
      fill="#C4E6F4"
    />
    <g opacity={0.1}>
      <path
        d="M218.657 108C232.351 108 243.452 96.8998 243.452 83.2071C243.452 69.5143 232.351 58.4142 218.657 58.4142C204.963 58.4142 193.862 69.5143 193.862 83.2071C193.862 96.8998 204.963 108 218.657 108Z"
        fill="#040000"
      />
      <path
        d="M259.285 76.3847C257.458 77.4008 248.257 77.7385 246.193 73.1721C244.129 68.5712 238.176 52.9435 218.657 52.5038C199.137 52.0306 189.936 69.9914 189.259 81.1882C189.023 85.1458 185.944 86.7021 180.294 86.7021C178.4 86.7021 153.908 86.9382 128.807 87.1754C127.758 87.1754 126.71 87.2094 125.661 87.2094C123.564 87.2094 121.466 87.2434 119.369 87.2769C118.354 87.2769 117.305 87.2769 116.29 87.3109C113.178 87.3109 110.167 87.345 107.19 87.378C106.209 87.378 105.228 87.4125 104.281 87.4125L187.703 3.99756C188.65 4.30222 189.598 4.63989 190.545 4.97857C191.458 5.35026 192.406 5.72245 193.353 6.09516C195.213 6.90708 197.04 7.75252 198.867 8.666C199.781 9.13924 200.694 9.61299 201.607 10.1202C203.4 11.1013 205.193 12.1503 206.953 13.2319C208.712 14.3144 210.437 15.465 212.129 16.6481C213.82 17.7987 215.478 19.0159 217.101 20.2675C217.71 20.7067 218.285 21.181 218.86 21.6202C218.894 21.6542 218.894 21.6542 218.894 21.6542C221.533 23.7178 224.002 25.7809 226.37 27.9125C227.115 28.5548 227.825 29.1982 228.536 29.841C230.87 31.9376 233.035 34.0692 235.064 36.0983C236.113 37.1808 237.162 38.2634 238.143 39.3114C242.439 43.8108 245.855 47.9034 248.224 50.982C248.224 50.982 248.224 51.015 248.258 51.015C249.341 52.4363 250.186 53.6544 250.795 54.5679C257.56 65.0198 261.755 74.9985 259.285 76.3847Z"
        fill="#040000"
      />
    </g>
  </svg>
);
export default SVGComponent;
