import * as React from "react";
const SVGComponent = (props) => (
  <svg
    style={{ width: "5rem", height: "5rem" }}
    viewBox="0 0 122 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60.9316 36.4334C59.4532 36.4334 58.2533 35.2335 58.2533 33.7551V17.9117C58.2533 16.4333 59.4532 15.2335 60.9316 15.2335C62.41 15.2335 63.6099 16.4333 63.6099 17.9117V33.7551C63.6099 35.2335 62.41 36.4334 60.9316 36.4334Z"
      fill="#FFC033"
    />
    <path
      d="M19.1919 78.1731H3.34857C1.87016 78.1731 0.670288 76.9732 0.670288 75.4948C0.670288 74.0164 1.87016 72.8165 3.34857 72.8165H19.1919C20.6704 72.8165 21.8702 74.0164 21.8702 75.4948C21.8702 76.9732 20.6704 78.1731 19.1919 78.1731Z"
      fill="#FFC033"
    />
    <path
      d="M118.515 78.1731H102.673C101.194 78.1731 99.9944 76.9732 99.9944 75.4948C99.9944 74.0164 101.194 72.8165 102.673 72.8165H118.515C119.993 72.8165 121.193 74.0164 121.193 75.4948C121.193 76.9732 119.993 78.1731 118.515 78.1731Z"
      fill="#FFC033"
    />
    <path
      d="M90.4463 48.6584C89.7606 48.6584 89.0763 48.3973 88.5527 47.8737C87.5068 46.8278 87.5068 45.1325 88.5527 44.0866L99.7546 32.8833C100.8 31.8375 102.496 31.8375 103.542 32.8833C104.588 33.9292 104.588 35.6246 103.542 36.6704L92.3398 47.8737C91.8162 48.3973 91.1319 48.6584 90.4463 48.6584Z"
      fill="#FFC033"
    />
    <path
      d="M31.417 48.6584C30.7313 48.6584 30.0457 48.3973 29.5234 47.8737L18.3202 36.6704C17.2743 35.6246 17.2743 33.9292 18.3202 32.8833C19.3647 31.8375 21.0627 31.8375 22.1073 32.8833L33.3105 44.0866C34.3564 45.1325 34.3564 46.8278 33.3105 47.8737C32.7882 48.3973 32.1026 48.6584 31.417 48.6584Z"
      fill="#FFC033"
    />
    <path
      d="M44.9597 39.6112C43.9085 39.6112 42.9108 38.9885 42.4837 37.9573L39.4264 30.5747C38.8599 29.2074 39.5094 27.6419 40.8767 27.0755C42.2413 26.5104 43.8094 27.1585 44.3759 28.5258L47.4331 35.9085C47.9996 37.2757 47.3501 38.8412 45.9828 39.4076C45.648 39.5442 45.3012 39.6112 44.9597 39.6112Z"
      fill="#FFC033"
    />
    <path
      d="M76.9035 39.6112C76.562 39.6112 76.2139 39.5456 75.8791 39.4063C74.5118 38.8398 73.8637 37.273 74.4301 35.9071L77.4887 28.5244C78.0552 27.1585 79.6206 26.5063 80.9879 27.0755C82.3552 27.6419 83.0033 29.2087 82.4369 30.5747L79.3783 37.9573C78.9511 38.9885 77.9547 39.6112 76.9035 39.6112Z"
      fill="#FFC033"
    />
    <path
      d="M99.4962 62.1998C98.445 62.1998 97.4487 61.5771 97.0202 60.5459C96.4537 59.1787 97.1032 57.6132 98.4691 57.0468L105.852 53.9895C107.222 53.423 108.785 54.0725 109.351 55.4398C109.917 56.807 109.268 58.3725 107.902 58.939L100.519 61.9962C100.185 62.1342 99.8377 62.1998 99.4962 62.1998Z"
      fill="#FFC033"
    />
    <path
      d="M22.367 62.1998C22.0255 62.1998 21.6787 62.1342 21.3439 61.9949L13.9612 58.9377C12.5939 58.3725 11.9458 56.8057 12.5109 55.4385C13.076 54.0712 14.6428 53.419 16.0101 53.9882L23.3928 57.0454C24.76 57.6106 25.4082 59.1774 24.8431 60.5446C24.4159 61.5771 23.4182 62.1998 22.367 62.1998Z"
      fill="#FFC033"
    />
    <path
      d="M86.9793 93.5437H34.8841C33.9373 93.5437 33.0601 93.0442 32.5781 92.2287C29.5878 87.1721 28.0062 81.3843 28.0062 75.4961C28.0062 57.3414 42.7756 42.572 60.9303 42.572C79.0851 42.572 93.8544 57.3414 93.8544 75.4961C93.8544 81.3857 92.2729 87.1721 89.284 92.2287C88.8032 93.0429 87.9261 93.5437 86.9793 93.5437Z"
      fill="#FFC033"
    />
    <path
      d="M108.272 93.5437H13.5916C12.1132 93.5437 10.9133 92.3438 10.9133 90.8654C10.9133 89.387 12.1132 88.1871 13.5916 88.1871H108.27C109.749 88.1871 110.948 89.387 110.948 90.8654C110.948 92.3438 109.75 93.5437 108.272 93.5437Z"
      fill="#15A2F1"
    />
    <path
      d="M95.1842 106.63H26.6791C25.2007 106.63 24.0009 105.43 24.0009 103.952C24.0009 102.473 25.2007 101.273 26.6791 101.273H95.1842C96.6626 101.273 97.8625 102.473 97.8625 103.952C97.8625 105.43 96.664 106.63 95.1842 106.63Z"
      fill="#15A2F1"
    />
  </svg>
);
export default SVGComponent;
