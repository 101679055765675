import * as React from "react";
const SVGComponent = (props) => (
  <svg
    style={{ width: "8rem", height: "8rem" }}
    viewBox="0 0 222 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.575 78.9656V41.3001L25.5495 41.4851V18.2864L54.735 18.1014V40.9486H51.7094V63.0743L28.575 78.9656Z"
      fill="#AB5832"
    />
    <path
      d="M111.501 31.5878L27.6711 116.058L28.3168 194.145L193.892 192.573L194.132 109.324L111.501 31.5878Z"
      fill="#FFF3E1"
    />
    <path
      d="M24.535 180.437L30.3278 177.236V114.189L24.9408 117.797C24.9224 117.797 25.5312 180.437 24.535 180.437Z"
      fill="#5F4E47"
    />
    <path
      d="M190.996 117.593V176.848L196.788 180.048V119.795L190.996 117.593Z"
      fill="#5F4E47"
    />
    <path
      d="M16.3807 98.2424L11.2704 107.696L24.9777 107.492C24.9777 107.492 110.486 24.0954 112.331 24.0214C114.563 23.9289 196.751 108.954 196.751 108.954L208.909 108.769L117.7 10.2391L104.306 9.35107L16.3807 98.2424Z"
      fill="#AB5832"
    />
    <path
      d="M0.607201 105.328C-0.831781 107.085 0.588752 108.01 1.75101 108.01C2.91326 108.01 16.3069 107.622 16.3069 107.622L108.623 17.5465C108.623 17.5465 110.745 15.3635 112.257 15.4375C113.899 15.5115 116.021 17.602 116.021 17.602L208.78 108.787C208.78 108.787 219.351 108.824 221.03 108.399C222.856 107.955 221.934 106.216 219.683 103.792C218.023 101.998 124.249 8.98108 122.699 7.3716C118.087 2.59867 115.836 -0.176296 111.483 0.00870134C107.11 0.193699 104.527 3.43115 100.155 7.4641C98.8638 8.68508 1.216 104.588 0.607201 105.328Z"
      fill="#ED6C31"
    />
    <path
      d="M165.721 137.961H184.631V122.181C184.631 121.484 184.494 120.793 184.228 120.149C183.962 119.505 183.572 118.92 183.08 118.427C182.588 117.934 182.005 117.543 181.362 117.276C180.72 117.009 180.031 116.872 179.336 116.872H165.721V137.961Z"
      fill="#A6CFD5"
    />
    <path
      d="M160.039 137.961V116.872H145.28C144.585 116.872 143.896 117.009 143.254 117.276C142.612 117.543 142.028 117.934 141.536 118.427C141.045 118.92 140.655 119.505 140.388 120.149C140.122 120.793 139.985 121.484 139.985 122.181V137.98H160.039V137.961Z"
      fill="#A6CFD5"
    />
    <path
      d="M160.039 143.955H139.986V158.755C139.986 160.163 140.543 161.514 141.536 162.509C142.529 163.505 143.876 164.065 145.28 164.065H160.039V143.955Z"
      fill="#A6CFD5"
    />
    <path
      d="M165.721 143.955V164.065H179.336C180.74 164.065 182.087 163.505 183.08 162.509C184.073 161.514 184.631 160.163 184.631 158.755V143.955H165.721Z"
      fill="#A6CFD5"
    />
    <path
      d="M61.0259 137.961H79.9356V122.181C79.9356 121.484 79.7987 120.793 79.5326 120.149C79.2665 119.505 78.8765 118.92 78.3848 118.427C77.8932 117.934 77.3095 117.543 76.6671 117.276C76.0247 117.009 75.3362 116.872 74.6409 116.872H61.0259V137.961Z"
      fill="#A6CFD5"
    />
    <path
      d="M55.3255 137.961V116.872H40.5667C39.8714 116.872 39.1829 117.009 38.5405 117.276C37.8981 117.543 37.3144 117.934 36.8228 118.427C36.3311 118.92 35.9411 119.505 35.675 120.149C35.4089 120.793 35.272 121.484 35.272 122.181V137.98H55.3255V137.961Z"
      fill="#A6CFD5"
    />
    <path
      d="M55.3255 143.955H35.2904V158.755C35.2904 160.163 35.8483 161.514 36.8412 162.509C37.8342 163.505 39.1809 164.065 40.5852 164.065H55.3439V143.955H55.3255Z"
      fill="#A6CFD5"
    />
    <path
      d="M61.0259 143.955V164.065H74.6409C76.0452 164.065 77.3919 163.505 78.3848 162.509C79.3778 161.514 79.9356 160.163 79.9356 158.755V143.955H61.0259Z"
      fill="#A6CFD5"
    />
    <path
      d="M86.0974 185.876C86.0974 185.876 85.8207 140.107 86.0974 128.545C86.3741 117.001 97.185 107.011 109.711 107.437C125.208 107.973 133.178 116.705 133.75 127.972C134.322 139.238 134.322 186.579 134.322 186.579L86.0974 185.876Z"
      fill="#D27857"
    />
    <path
      d="M91.8718 188.355C91.8718 188.355 91.6504 139.589 91.8718 130.561C92.1485 119.369 100.561 113.486 110.339 114.041C121.721 114.689 127.348 120.757 127.791 129.581C128.234 138.387 128.234 188.392 128.234 188.392L91.8718 188.355Z"
      fill="#AB5932"
    />
    <path
      d="M122.349 148.599C122.312 140.57 122.238 133.484 122.127 131.32C121.832 125.492 117.7 120.96 109.675 120.683C103.199 120.461 97.8676 125.529 97.72 131.505C97.6646 133.706 97.6831 140.625 97.72 148.506L122.349 148.599Z"
      fill="#D27857"
    />
    <path
      d="M97.7756 157.608C97.8494 168.357 97.9416 178.254 97.9416 178.254L122.423 178.328C122.423 178.328 122.423 168.468 122.386 157.719L97.7756 157.608Z"
      fill="#D27857"
    />
    <path
      d="M122.423 152.724C122.33 155.333 123.382 157.442 126.463 157.442C129.544 157.442 130.669 155.999 130.89 153.205C131.075 150.708 129.359 148.488 126.463 148.673C123.585 148.876 122.496 150.893 122.423 152.724Z"
      fill="#FFBA02"
    />
    <path
      d="M140.096 203.598C140.096 203.598 160.168 203.802 174.669 203.376C189.169 202.951 199.666 203.598 201.825 201.415C203.983 199.251 203.946 180.807 193.892 175.812C184.649 171.224 178.672 175.886 175.499 176.2C171.311 176.626 166.127 174.017 162.327 173.943C146.627 173.61 141.129 185.987 141.129 185.987L140.096 203.598Z"
      fill="#728037"
    />
    <path
      d="M20.0335 202.081C21.7123 203.654 34.534 203.709 50.0122 203.598C65.4905 203.487 77.9432 203.635 77.9432 203.635L80.526 187.966L77.3898 185.358C77.3898 185.358 77.7403 177.514 67.8704 174.831C61.0444 172.981 55.104 176.034 49.3665 176.126C43.6291 176.237 38.3344 173.629 31.9512 175.146C26.0108 176.552 21.3802 180.437 19.4985 188.281C18.1886 193.72 18.5207 200.657 20.0335 202.081Z"
      fill="#728037"
    />
    <path
      d="M76.0983 219.989L76.2275 203.617H77.4266L77.4082 185.376H141.48L141.258 203.617H142.66V219.564C142.642 219.545 76.0983 220.082 76.0983 219.989Z"
      fill="#B0B0B0"
    />
    <path
      d="M142.642 212.83V219.545L76.08 219.989L76.1353 212.774L142.642 212.83Z"
      fill="#9B9B9B"
    />
    <path
      d="M77.3898 196.513L141.314 196.532V203.598H77.3898V196.513Z"
      fill="#9B9B9B"
    />
  </svg>
);
export default SVGComponent;
