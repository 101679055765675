import * as React from "react";
const SVGComponent = (props) => (
  <svg
    style={{ width: "8rem", height: "8rem" }}
    viewBox="0 0 246 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 16">
      <path
        id="Vector"
        d="M3.01413 135.771C6.7603 140.05 35.3712 140.201 69.9102 139.899C104.449 139.597 132.237 140 132.237 140L138 97.36L131.002 90.2617C131.002 90.2617 131.784 68.9165 109.76 61.6168C94.5279 56.5826 81.2722 64.8891 68.4693 65.1408C55.6664 65.4429 43.8516 58.3446 29.6079 62.4727C16.3522 66.2987 6.0193 76.8706 1.82029 98.2158C-1.10255 113.016 -0.36155 131.895 3.01413 135.771Z"
        fill="#728037"
      />
      <g id="Group">
        <path
          id="Vector_2"
          d="M198.814 36.0746H57.7942V57.547H198.814V36.0746Z"
          fill="#EAC36E"
        />
        <path
          id="Vector_3"
          d="M198.814 101.35H57.7942V122.823H198.814V101.35Z"
          fill="#EAC36E"
        />
      </g>
      <g id="Group_2">
        <path
          id="Vector_4"
          d="M70.0666 16.568L53.0329 0L36 16.568V140H70.0666V16.568Z"
          fill="#FCD577"
        />
        <path
          id="Vector_5"
          d="M119.711 16.568L102.678 0L85.6443 16.568V140H119.711V16.568Z"
          fill="#FCD577"
        />
        <path
          id="Vector_6"
          d="M169.356 16.568L152.322 0L135.289 16.568V140H169.356V16.568Z"
          fill="#FCD577"
        />
        <path
          id="Vector_7"
          d="M219 16.568L201.966 0L184.933 16.568V140H219V16.568Z"
          fill="#FCD577"
        />
      </g>
      <g id="Group_3">
        <path
          id="Vector_8"
          d="M201.968 50.0316C203.738 50.0316 205.173 48.5895 205.173 46.8107C205.173 45.0319 203.738 43.5898 201.968 43.5898C200.198 43.5898 198.763 45.0319 198.763 46.8107C198.763 48.5895 200.198 50.0316 201.968 50.0316Z"
          fill="#415E72"
        />
        <path
          id="Vector_9"
          d="M201.968 115.307C203.738 115.307 205.173 113.865 205.173 112.087C205.173 110.308 203.738 108.866 201.968 108.866C200.197 108.866 198.763 110.308 198.763 112.087C198.763 113.865 200.197 115.307 201.968 115.307Z"
          fill="#415E72"
        />
        <path
          id="Vector_10"
          d="M152.32 50.0316C154.09 50.0316 155.525 48.5895 155.525 46.8107C155.525 45.0319 154.09 43.5898 152.32 43.5898C150.55 43.5898 149.115 45.0319 149.115 46.8107C149.115 48.5895 150.55 50.0316 152.32 50.0316Z"
          fill="#415E72"
        />
        <path
          id="Vector_11"
          d="M152.32 115.307C154.09 115.307 155.525 113.865 155.525 112.087C155.525 110.308 154.09 108.866 152.32 108.866C150.55 108.866 149.115 110.308 149.115 112.087C149.115 113.865 150.55 115.307 152.32 115.307Z"
          fill="#415E72"
        />
        <path
          id="Vector_12"
          d="M102.677 50.0316C104.447 50.0316 105.882 48.5895 105.882 46.8107C105.882 45.0319 104.447 43.5898 102.677 43.5898C100.907 43.5898 99.4716 45.0319 99.4716 46.8107C99.4716 48.5895 100.907 50.0316 102.677 50.0316Z"
          fill="#415E72"
        />
        <path
          id="Vector_13"
          d="M102.677 115.307C104.447 115.307 105.882 113.865 105.882 112.087C105.882 110.308 104.447 108.866 102.677 108.866C100.907 108.866 99.4717 110.308 99.4717 112.087C99.4717 113.865 100.907 115.307 102.677 115.307Z"
          fill="#415E72"
        />
        <path
          id="Vector_14"
          d="M53.0328 50.0316C54.8028 50.0316 56.2378 48.5895 56.2378 46.8107C56.2378 45.0319 54.8028 43.5898 53.0328 43.5898C51.2627 43.5898 49.8278 45.0319 49.8278 46.8107C49.8278 48.5895 51.2627 50.0316 53.0328 50.0316Z"
          fill="#415E72"
        />
        <path
          id="Vector_15"
          d="M53.0328 115.307C54.8028 115.307 56.2378 113.865 56.2378 112.087C56.2378 110.308 54.8028 108.866 53.0328 108.866C51.2627 108.866 49.8278 110.308 49.8278 112.087C49.8278 113.865 51.2627 115.307 53.0328 115.307Z"
          fill="#415E72"
        />
      </g>
      <path
        id="Vector_16"
        d="M128 139.909C128 139.909 165.673 140.291 192.888 139.492C220.104 138.694 239.806 139.909 243.857 135.813C247.908 131.751 247.839 97.1422 228.968 87.7697C211.621 79.1608 200.402 87.9085 194.446 88.4986C186.586 89.297 176.857 84.4025 169.724 84.2636C140.257 83.6388 129.939 106.862 129.939 106.862L128 139.909Z"
        fill="#728037"
      />
    </g>
  </svg>
);
export default SVGComponent;
