import * as React from "react";
const SVGComponent = (props) => (
  <svg
    viewBox="0 0 1440 227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "100%", height: "100%" }}
  >
    <path
      d="M439 54.4998C203.8 -11.5002 47.3333 -6.16679 -1.5 15.9999V229H1442V54.5C1359.33 37.6666 1328.2 -8.30013 1139 70.4999C902.5 169 733 137 439 54.4998Z"
      fill="url(#paint0_linear_19_277)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_19_277"
        x1={720}
        y1={229}
        x2={720}
        y2={-45}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default SVGComponent;
